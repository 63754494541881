import { apiGet, apiPost } from './apiClient';

const getOrgAPI = async () => {
    const response = await apiGet({ url: `/org` });
    return response;
};

const accountLoginAPI = async () => {
    const response = await apiGet({ url: `/account/login` });
    return response;
};

const getOrgListAPI = async () => {
    const response = await apiGet({ url: `/org/list` });
    return response;
};

const addPaymentMethodAPI = async ({ data }) => {
    const response = await apiPost({ url: `/org/pm`, data });
    return response;
};

const makePaymentMethodDefaultAPI = async ({ data }) => apiPost({ url: `/org/pm/default`, data });

const getInvoiceListAPI = async () => {
    const response = await apiGet({ url: `/payments/invoices` });
    return response;
};

const updateUserPasswordAPI = async ({ oldPassword, newPassword }) => {
    const response = await apiPost({ url: `/user/password?old=${oldPassword}&new=${newPassword}` });
    return response;
};

const getPricesAPI = async () => {
    const response = await apiGet({ url: `/payments/prices` });
    return response;
};

const checkDiscountAPI = async ({ id }) => {
    const response = await apiGet({ url: `/payments/discounts?id=${id}` });
    return response;
};

export {
    getOrgAPI,
    getOrgListAPI,
    accountLoginAPI,
    addPaymentMethodAPI,
    getInvoiceListAPI,
    updateUserPasswordAPI,
    getPricesAPI,
    checkDiscountAPI,
    makePaymentMethodDefaultAPI
};
