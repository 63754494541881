import { apiGet, apiUpdate, apiPost, apiDelete } from './apiClient';

const getUserAPI = async () => {
    const response = await apiGet({ url: `/user` });
    return response;
};
const updateUserAPI = async ({ data }) => {
    const response = await apiUpdate({ url: `/user`, data });
    return response;
};

const adminUpdateUserPasswordAPI = async ({ email, newPassword }) => {
    const response = await apiPost({ url: `/admin/user/password?email=${email}&newPassword=${newPassword}` });
    return response;
};

const deleteUserAPI = async ({ id }) => {
    const response = await apiDelete({ url: `/user?id=${id}` });
    return response;
};
const adminUpdateUserAPI = async ({ id, data }) => {
    const response = await apiUpdate({ url: `/admin/user?id=${id}`, data });
    return response;
};
const createUserAPI = async ({ data }) => {
    const response = await apiPost({ url: `/user`, data });
    return response;
};
const getUserListAPI = async () => {
    const response = await apiGet({ url: `/user/list` });
    return response;
};

const lockUserAPI = async ({ id }) => {
    const response = await apiUpdate({ url: `/user/lock?id=${id}` });
    return response;
};

export {
    getUserAPI,
    updateUserAPI,
    getUserListAPI,
    lockUserAPI,
    createUserAPI,
    adminUpdateUserAPI,
    adminUpdateUserPasswordAPI,
    deleteUserAPI
};
